
import dateformat from 'dateformat';
import { computed, onUnmounted, ref, watch, onMounted } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useRoute, useRouter } from 'vue-router';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { Empty, Loader, StatusBool } from '../../components/ui';
import { Paginator } from '../../components/common';
import { useStores } from '@/store/Stores';

export default {
  components: {
    DataTable,
    Column,
    Empty,
    Loader,
    Paginator,
    Datepicker
  },
  setup() {
    const { companyTransactionsStore, companyTransactionsStoreState, headerStore } = useStores();

    const router = useRouter();
    const route = useRoute();

    const name = computed(() => companyTransactionsStoreState.data.company.title);

    const dateRange = ref();

    const refresh = () => {
      const dateFrom = route.query.date_from ? route.query.date_from : dateformat(dateRange.value[0], 'yyyy-mm-dd');

      const dateTo = route.query.date_to ? route.query.date_to : dateformat(dateRange.value[1], 'yyyy-mm-dd');

      companyTransactionsStore.init({
        per_page: 15,
        page: route.query.page,
        company: route.params.companyID,
        date_from: dateFrom,
        date_to: dateTo
      });
    };

    const exportTransactions = () => {
      const dateFrom = route.query.date_from ? route.query.date_from : dateformat(dateRange.value[0], 'yyyy-mm-dd');

      const dateTo = route.query.date_to ? route.query.date_to : dateformat(dateRange.value[1], 'yyyy-mm-dd');

      companyTransactionsStore.download({
        company: route.params.companyID,
        date_from: dateFrom,
        date_to: dateTo
      });
    };

    onMounted(() => {
      dateRange.value = [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()];

      refresh();
    });

    watch(route, () => {
      refresh();
    });

    const onDatesChanged = (e: Date[]) => {
      const dateFrom = dateformat(e[0], 'yyyy-mm-dd');
      const dateTo = dateformat(e[1], 'yyyy-mm-dd');

      router.push({
        path: `/transactions`,
        query: {
          date_from: dateFrom,
          date_to: dateTo
        }
      });
    };

    headerStore.setTitle(name);

    onUnmounted(() => {
      headerStore.setTitle('');
    });

    return {
      state: companyTransactionsStoreState,
      dateformat,

      dateRange,
      onDatesChanged,
      exportTransactions
    };
  }
};
