
import { computed, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStores } from '@/store/Stores';

export default {
  setup() {
    const { companyStore, companyStoreState, headerStore } = useStores();

    const route = useRoute();
    const name = computed(() => companyStoreState.data.title);

    companyStore.init(route.params.companyID);

    const tabs = [
      { label: 'tabs.company_profile', to: `/companies/${route.params.companyID}/common` },
      { label: 'tabs.staff', to: `/companies/${route.params.companyID}/staff` },
      { label: 'tabs.angles', to: `/companies/${route.params.companyID}/angles` },
      { label: 'tabs.settings_bg', to: `/companies/${route.params.companyID}/scenes` }
    ];

    headerStore.setTitle(name);
    headerStore.setTabs(tabs);

    onUnmounted(() => {
      headerStore.setTitle('');
      headerStore.setTabs([]);
    });
    return {};
  }
};
